<template>
    <div>
        <!-- Page Title Section -->
        <section class="page-title">
            <div class="pattern-layer-one" :style="{backgroundImage: 'url('+bgPattern5+')'}"></div>
            <div class="pattern-layer-two" :style="{backgroundImage: 'url('+bgPattern6+')'}"></div>
            <div class="icon-layer-one" :style="{backgroundImage: 'url('+icon5+')'}"></div>
            <div class="icon-layer-two" :style="{backgroundImage: 'url('+icon5+')'}"></div>
            <div class="icon-layer-three" :style="{backgroundImage: 'url('+icon6+')'}"></div>
            <div class="icon-layer-four" :style="{backgroundImage: 'url('+iconPattern1+')'}"></div>
            <div class="icon-layer-five" :style="{backgroundImage: 'url('+iconPattern2+')'}"></div>
            <div class="auto-container">
                <h1>Contact Us</h1>
                <ul class="page-breadcrumb">
                    <li>
                        <router-link :to="{name: 'Home'}">home</router-link>
                    </li>
                    <li>Contact Us</li>
                </ul>
            </div>
        </section>
        <!-- End Page Title Section -->

        <!-- Contact Page Section -->
        <section class="contact-page-section">
            <div class="auto-container">
                <div class="row clearfix">

                    <!-- Info Column -->
                    <div class="info-column col-lg-4 col-md-12 col-sm-12">
                        <div class="inner-column">
                            <ul class="contact-info-list">
                                <li class="clearfix">
                                    <span class="icon flaticon-call-1"></span>
                                    <a href="tel:+12136312284">+1 (213) 631 2284</a>
<!--                                    <a href="tel:+999-222-3333-88">+999 222 3333 88</a>-->
                                </li>
                                <li class="clearfix">
                                    <span class="icon flaticon-email"></span>
                                    <a href="mailto:trustearnss@gmail.com">trustearnss@gmail.com</a>
<!--                                    <a href="mailto:example@Kidum.com">example@domain.com</a>-->
                                </li>
                                <li class="clearfix">
                                    <span class="icon flaticon-maps-and-flags"></span>
                                    23rd Avenue, Fresno California, U.S.A
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- Form Column -->
                    <div class="form-column col-lg-8 col-md-12 col-sm-12">
                        <div class="inner-column">

                            <!-- Contact Form -->
                            <div class="contact-form">
                                <form @submit.prevent="submitContact" id="contact-form" ref="contactForm">
                                    <div class="row clearfix">

                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label>Full Name</label>
                                            <input type="text" name="username" placeholder="First Name" v-model="contact.name" required>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label>Email</label>
                                            <input type="email" name="email" placeholder="example@mail.com" v-model="contact.email" required>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <label>Subject</label>
                                            <input type="text" name="subject" placeholder="Subject" v-model="contact.subject" required>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <label>Message</label>
                                            <textarea class="" name="message" placeholder="Message" v-model="contact.message"></textarea>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <button class="theme-btn btn-style-one" type="submit" name="submit-form">
                                                <span class="txt">Submit Now</span>
                                            </button>
                                        </div>

                                    </div>
                                </form>

                            </div>
                            <!-- End Contact Form -->

                        </div>
                    </div>

                </div>
            </div>
        </section>
        <!-- End Contact Page Section -->

        <!-- Map Section -->
        <section class="contact-map-section">
            <div class="outer-container">
                <!-- Map Boxed -->
                <div class="map-boxed">
                    <!--Map Outer-->
                    <div class="map-outer">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d204504.28568247368!2d-119.93464786746837!3d36.785451319238824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s23rd%20Avenue%2C%20Fresno%20California%2CU.S!5e0!3m2!1sen!2sng!4v1632583359794!5m2!1sen!2sng" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Map Section -->

    </div>
</template>

<script>
import icon1 from "../../assets/images/icons/icon-1.png";
import icon2 from "../../assets/images/icons/icon-2.png";
import icon3 from "../../assets/images/icons/icon-3.png";
import icon4 from "../../assets/images/icons/icon-4.png";
import bgPattern5 from "../../assets/images/background/pattern-5.png";
import bgPattern6 from "../../assets/images/background/pattern-6.png";
import icon5 from "../../assets/images/icons/icon-5.png";
import icon6 from "../../assets/images/icons/icon-6.png";
import iconPattern1 from "../../assets/images/icons/pattern-1.png";
import iconPattern2 from "../../assets/images/icons/pattern-2.png";
import Contact from "../../models/contact";

export default {
    name: "Privacy",
    data(){
        return {
            icon1, icon2, icon3, icon4,
            bgPattern5, bgPattern6,
            icon5, icon6, iconPattern1,
            iconPattern2,
            contact: new Contact().data
        }
    },
    methods: {
        async submitContact(){
            if(!this.contact.name || !this.contact.email || !this.contact.name || !this.contact.subject || !this.contact.message){
                return toastr.warning("Complete all fields")
            }
            const loader = this.$loading.show({container: this.$refs.contactForm});
            const response = await this.$store.dispatch('contact/addContact', this.contact);
            loader.hide();
            if(response.status){
                this.contact = new Contact().data;
                alert("Message Received, Note that our representative will contact you within the next 24 hours")
            }else{
                alert(response.message);
            }
        }
    }
}
</script>

<style>
.pattern-layer{
    background-image: none!important;
}
</style>